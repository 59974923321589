import React from 'react';

const Footer = (props) => {
  return(  
    <div>
      {console.log(JSON.stringify(props))}
    
    </div>
  );

};

export default Footer;